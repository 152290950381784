import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import HomeSpotlightItems from "./components/HomeSpotlightItems/HomeSpotlightItems";

const target = document.getElementById('home-spotlight-items');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

if (target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="home-spotlight-items"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.home.friendsPack',
				'frontend.home.bierDegu',
				'friendspack.button.config.label',
				'adventcalendar.button.config.label',
				'frontend.home.deguBtn3',
				'frontend.home.deguBtn6',
				'frontend.home.deguBtn9']}>
			<HomeSpotlightItems/>
		</BundleEntry>,
		document.getElementById('home-spotlight-items')
	);
}
