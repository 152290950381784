import ImgDegu3Pack from "./Images/Degupack_3pack.png";
import ImgDegu6Pack from "./Images/Degupack_6pack.png";
import ImgDegu9Pack from "./Images/Degupack_9pack.png";
import {useCallback, useEffect, useState} from "react";
import {redirectTo} from "../../lib/utility";
import {FormattedMessage, useIntl} from "react-intl";
import parse from "html-react-parser";

export default function HomeSpotlightItems() {

	const intl = useIntl();

	const [choosenDeguSize, setChoosenDeguSize] = useState(3);
	const [deguBtnText, setDeguBtnText] = useState(intl.formatMessage({id: 'frontend.home.deguBtn3'}));

	const setDeguSize = (deguSize: number) => {
		setChoosenDeguSize(deguSize);
		setStyle();
	}

	const selectedDeguStyle = "background-color: #EDEDED; border: 2px; border-radius: 9999px;";

	const setStyle = useCallback(() => {
		var choosenDeguImg3 = document.getElementById('home-degu-img-3');
		var choosenDeguImg6 = document.getElementById('home-degu-img-6');
		var choosenDeguImg9 = document.getElementById('home-degu-img-9');

		switch (choosenDeguSize) {
			case 3:
				if (choosenDeguImg3 != null)
					choosenDeguImg3.setAttribute("style", selectedDeguStyle);
				if (choosenDeguImg6 != null)
					choosenDeguImg6.removeAttribute("style");
				if (choosenDeguImg9 != null)
					choosenDeguImg9.removeAttribute("style");

				setDeguBtnText('frontend.home.deguBtn3');
				break;
			case 6:
				if (choosenDeguImg3 != null)
					choosenDeguImg3.removeAttribute("style");
				if (choosenDeguImg6 != null)
					choosenDeguImg6.setAttribute("style", selectedDeguStyle);
				if (choosenDeguImg9 != null)
					choosenDeguImg9.removeAttribute("style");

				setDeguBtnText('frontend.home.deguBtn6');
				break;
			case 9:
				if (choosenDeguImg3 != null)
					choosenDeguImg3.removeAttribute("style");
				if (choosenDeguImg6 != null)
					choosenDeguImg6.removeAttribute("style");
				if (choosenDeguImg9 != null)
					choosenDeguImg9.setAttribute("style", selectedDeguStyle);

				setDeguBtnText('frontend.home.deguBtn9');
				break;
		}
	}, [choosenDeguSize]);

	useEffect(() => {
		setStyle();
	}, [setStyle]);

	function navigateToDegu() {
		redirectTo('/biere/degustationspack?degusize=' + choosenDeguSize);
	}

	function navigateToFriendspack() {
		redirectTo('https://friendspack.justdrink.ch/#/step1?fromJd');
	}

	return (
		<div id="home-spotlight-items"
			 className={'tw-flex tw-flex-col md:tw-flex-row tw-h-spotlightItemsHeight tw-ml-0 tw-mt-0 tw-mt-16 tw-mr-0 tw-mb-16 md:tw-mb-32 tw-p-0'}>

			<div id={"home-degu"}
				 className={'tw-w-full md:tw-w-4/6 tw-h-4/6 md:tw-h-full tw-p-8 md:tw-p-12 md:tw-mr-4 tw-mb-8 md:tw-mb-0 tw-flex tw-flex-col md:tw-items-center tw-bg-homeComponentsBg'}>

				<div className={'tw-flex md:tw-h-20 tw-mb-6 md:tw-mb-4 tw-items-center'}>
					<div id={"home-degu-title"}
						 className={'tw-text-4xl md:tw-text-6xl tw-black tw-font-bold tw-font-gotham-black'}>
						<FormattedMessage id={"frontend.home.bierDegu"}/>
					</div>
				</div>


				<div id={"home-degu-imgs"}
					 className={'tw-flex tw-flex-grow tw-w-full md:tw-space-x-4 tw-justify-between md:tw-justify-center tw-items-center tw-mb-8 md:tw-mb-0'}>
					<div id={"home-degu-img-3"}
						 className={'tw-h-40 tw-w-40 md:tw-h-80 md:tw-w-80 tw-mix-blend-multiply tw-flex tw-justify-center tw-items-center'}>
						<button className={'tw-flex tw-justify-center tw-items-center'}
								onClick={() => setDeguSize(3)}>
							<img className={'tw-h-32 tw-w-28 md:tw-h-64 md:tw-w-56'} src={ImgDegu3Pack}
								 alt={"3 Pack Degustation"}/>
						</button>
					</div>

					<div id={"home-degu-img-6"}
						 className={'tw-h-40 tw-w-40 md:tw-h-80 md:tw-w-80 tw-mix-blend-multiply tw-flex tw-justify-center tw-items-center'}>
						<button className={'tw-flex tw-justify-center tw-items-center'}
								onClick={() => setDeguSize(6)}>
							<img className={'tw-h-32 tw-w-28 md:tw-h-64 md:tw-w-56'} src={ImgDegu6Pack}
								 alt={"6 Pack Degustation"}/>
						</button>
					</div>

					<div id={"home-degu-img-9"}
						 className={'tw-h-40 tw-w-40 md:tw-h-80 md:tw-w-80 tw-mix-blend-multiply tw-flex tw-justify-center tw-items-center'}>
						<button className={'tw-flex tw-justify-center tw-items-center'}
								onClick={() => setDeguSize(9)}>
							<img className={'tw-h-32 tw-w-28 md:tw-h-64 md:tw-w-56'} src={ImgDegu9Pack}
								 alt={"9 Pack Degustation"}/>
						</button>
					</div>
				</div>

				<div id={"home-degu-btn-items"}
					 className={'tw-flex tw-w-full tw-h-14 md:tw-h-20 tw-items-center tw-justify-center'}>

					<button id={"home-degu-btn"}
							className={'tw-h-full tw-w-full md:tw-w-spotlightButtonWidth tw-bg-black tw-text-white tw-rounded'}
							onClick={navigateToDegu}>
						{parse(intl.formatMessage({id: deguBtnText}))}
					</button>
				</div>
			</div>


			<a id={"home-personal-pack"} href={"https://friendspack.justdrink.ch/#/step1?fromJd"}
			   className={'tw-flex tw-flex-col tw-justify-between md:tw-h-full md:tw-w-2/6 md:tw-ml-4 tw-p-8 md:tw-p-12 md:tw-pt-0 tw-bg-feldschlösschenBlue'}>
				<div className={'tw-flex tw-flex-row md:tw-flex-col tw-mb-4 md:tw-mb-0 md:tw-w-full'}>
					<div id={"home-personal-pack-img"}
						 className={'tw-m-0 tw-flex tw-justify-center tw-items-center md:tw-items-start tw-w-full tw-h-full'}>
						<img className={'tw-h-40 md:tw-h-spotlightItemsFriendspackHeight'}
							 src="/cmsstatic/friendspack-productImage504x450.png"
							 alt={"Feldschlösschen Friendspack"}/>
					</div>

					<div id={"home-personal-pack-texts"}
						 className={'tw-flex tw-flex-grow tw-justify-center tw-items-center md:tw-justify-start md:tw-items-start tw-ml-4 md:tw-ml-0 tw-h-full'}>
						<div className={'tw-flex tw-flex-col tw-justify-center'}>
							<div
								className={'tw-text-white tw-font-gotham tw-font-bold tw-text-productDetailDescription'}>
								<FormattedMessage id={"frontend.home.friendsPack"}/>
							</div>

							<div className={'tw-text-white tw-font-gotham tw-text-homeFriendsPackSubTitle'}>8x33cl
							</div>
						</div>
					</div>
				</div>
				<div id={"home-personal-pack-btn"} className={'tw-h-14 md:tw-h-20'}>
					<button className={'tw-border-2 tw-h-full tw-w-full tw-border-white tw-text-white tw-rounded'}
							onClick={navigateToFriendspack}>
						<FormattedMessage id={"friendspack.button.config.label"}/>
					</button>
				</div>
			</a>
		</div>
	)
}